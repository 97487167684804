<template>
  <main class="container_order container">
    <template v-if="getItems">
      <div v-for="item in getItems" :key="item?.id" class="cart__list">
        <template v-if="item?.soles?.length">
          <div
            v-for="(sole, index) in item.soles"
            :key="sole?.id"
            class="cart__item mb-5"
          >
            <div class="cart__item-container cart__item-container--top">
              <div>
                <div class="item-container__title">
                  {{ $t('PRODUCT_DESCRIPTION.table.image') }}
                </div>
                <div class="pa-4" style="max-height: 199px; height: 100%">
                  <img
                    :title="getImageName(item?.photo)"
                    style="width: 100%; height: 100%; object-fit: contain"
                    :src="item && baseUrl + item?.photo"
                    :alt="getImageName(item?.photo)"
                  />
                </div>
              </div>
              <div>
                <div class="item-container__title">
                  {{ $t('PRODUCT_DESCRIPTION.table.details') }}
                </div>
                <div class="pa-4 d-flex flex-column">
                  <span style="font-weight: 700" class="mb-2">
                    {{ item?.name[activeLang.language] }}
                  </span>
                  <span class="mb-2">
                    <span style="font-weight: 700">Подошва: </span>
                    {{ sole?.name }}
                  </span>
                  <div>
                    <span style="font-weight: 700"
                      >{{ $t('PRODUCT_DESCRIPTION.branding') }}:
                    </span>
                    <ul class="pa-0 ma-0">
                      <li
                        v-for="(brand, index) in sole.selected_brands"
                        :key="brand?.id"
                      >
                        <span>{{ index + 1 }}) </span>
                        <span>{{ brand?.name }} = {{ brand?.price }}$</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="item-container__inner">
                <div>
                  <div class="item-container__title">
                    {{ $t('PRODUCT_DESCRIPTION.table.price') }}
                  </div>
                  <div
                    v-for="(size, index) in item?.sizes?.filter(
                      (size) => size?.soleId === sole?.id
                    )"
                    :key="index"
                    :style="
                      index !==
                      item?.sizes?.filter((size) => size?.soleId === sole?.id) -
                        1
                        ? 'border-bottom: 1px solid #f5f5f5;'
                        : ''
                    "
                    class="position-relative item-container__content"
                  >
                    <div
                      class="d-flex align-center justify-center"
                      style="font-weight: 700"
                    >
                      {{ (size?.price && size?.price) || 'not defined' }}$
                    </div>
                  </div>
                  <div class="item-container__title">
                    {{ $t('PRODUCT_DESCRIPTION.table.pre_count') }}
                  </div>
                </div>
                <div>
                  <div class="item-container__title">
                    {{ $t('PRODUCT_DESCRIPTION.table.color') }}
                  </div>
                  <div
                    v-for="(size, index) in item?.sizes?.filter(
                      (size) => size.soleId === sole?.id
                    )"
                    :key="index"
                    :style="
                      index !==
                      item?.sizes?.filter((size) => size.soleId === sole?.id)
                        ?.length -
                        1
                        ? 'border-bottom: 1px solid #f5f5f5;'
                        : ''
                    "
                    class="position-relative item-container__content"
                  >
                    <div class="d-flex align-center justify-center">
                      <img
                        class="item-container__content-color"
                        :src="
                          item?.colors
                            ?.filter((color) => size.colorId === color?.id)[0]
                            ?.photo_path.includes(baseUrl)
                            ? item?.colors?.filter(
                                (color) => size.colorId === color?.id
                              )[0]?.photo_path
                            : baseUrl +
                              item?.colors?.filter(
                                (color) => size.colorId === color?.id
                              )[0]?.photo_path
                        "
                        :alt="
                          getImageName(
                            item?.colors?.filter(
                              (color) => size.colorId === color?.id
                            )[0]?.photo_path
                          )
                        "
                        :title="
                          getImageName(
                            item?.colors?.filter(
                              (color) => size.colorId === color?.id
                            )[0]?.photo_path
                          )
                        "
                      />
                    </div>
                  </div>
                  <div class="item-container__title">
                    {{ $t('PRODUCT_DESCRIPTION.table.color') }}
                  </div>
                  <div
                    :style="
                      index !==
                      item?.sizes?.filter((size) => size.soleId === sole?.id)
                        ?.length -
                        1
                        ? 'border-bottom: 1px solid #f5f5f5;'
                        : ''
                    "
                    class="position-relative item-container__content"
                  >
                    <div
                      class="d-flex align-center justify-center"
                      style="font-weight: 700; font-size: 20px"
                    >
                      {{
                        item?.colors?.filter(
                          (color) => color.soleId === sole?.id
                        )?.length
                      }}
                    </div>
                  </div>
                </div>
                <div>
                  <div class="item-container__title">
                    {{ $t('PRODUCT_DESCRIPTION.table.size') }}
                  </div>
                  <div
                    v-for="(size, index) in item?.sizes?.filter(
                      (size) => size.soleId === sole?.id
                    )"
                    :key="index"
                    :style="
                      index !==
                      item?.sizes?.filter((size) => size.soleId === sole?.id)
                        ?.length -
                        1
                        ? 'border-bottom: 1px solid #f5f5f5;'
                        : ''
                    "
                    class="position-relative item-container__content"
                  >
                    <div
                      class="d-flex align-center justify-center"
                      style="font-weight: 700; font-size: 20px"
                    >
                      {{ size?.name }}
                    </div>
                  </div>
                  <div class="item-container__title">
                    {{ $t('PRODUCT_DESCRIPTION.table.size') }}
                  </div>
                  <div
                    :style="
                      index !==
                      item?.sizes?.filter((size) => size.soleId === sole?.id)
                        ?.length -
                        1
                        ? 'border-bottom: 1px solid #f5f5f5;'
                        : ''
                    "
                    class="position-relative item-container__content"
                  >
                    <div
                      class="d-flex align-center justify-center"
                      style="font-weight: 700; font-size: 20px"
                    >
                      {{
                        item?.sizes?.filter((size) => size.soleId === sole?.id)
                          ?.length
                      }}
                    </div>
                  </div>
                </div>
                <div>
                  <div class="item-container__title">
                    {{ $t('PRODUCT_DESCRIPTION.table.count') }}
                  </div>
                  <div
                    v-for="(size, index) in item?.sizes?.filter(
                      (size) => size.soleId === sole?.id
                    )"
                    :key="index"
                    :style="
                      index !==
                      item?.sizes?.filter((size) => size.soleId === sole?.id)
                        ?.length -
                        1
                        ? 'border-bottom: 1px solid #f5f5f5;'
                        : ''
                    "
                    class="position-relative item-container__content"
                  >
                    <div class="d-flex align-center justify-center pa-2">
                      <button
                        class="content__button content__button--decrease"
                        @click="size?.count > 1 ? size.count-- : ''"
                      >
                        -
                      </button>
                      <span
                        class="text-center px-3 py-1 mx-1"
                        style="border-radius: 5px; border: 1px solid #cecece"
                      >
                        {{ size?.count }}
                      </span>
                      <button
                        class="content__button content__button--increase"
                        @click="size.count++"
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div class="item-container__title">
                    {{ $t('PRODUCT_DESCRIPTION.table.count') }}
                  </div>
                  <div
                    :style="
                      index !==
                      item?.sizes?.filter((size) => size.soleId === sole?.id)
                        ?.length -
                        1
                        ? 'border-bottom: 1px solid #f5f5f5;'
                        : ''
                    "
                    class="position-relative item-container__content"
                  >
                    <div
                      class="d-flex align-center justify-center"
                      style="font-weight: 700; font-size: 20px"
                    >
                      {{
                        item?.sizes.reduce((sum, curr) => {
                          return (sum += curr?.soleId === sole.id && curr.count)
                        }, 0)
                      }}
                    </div>
                  </div>
                </div>
                <div>
                  <div class="item-container__title">
                    {{ $t('PRODUCT_DESCRIPTION.table.total') }}
                  </div>
                  <div
                    v-for="(size, index) in item?.sizes?.filter(
                      (size) => size.soleId === sole?.id
                    )"
                    :key="index"
                    class="position-relative item-container__content"
                  >
                    <div
                      :style="
                        index !==
                        item?.sizes?.filter((size) => size.soleId === sole?.id)
                          ?.length -
                          1
                          ? 'border-bottom: 1px solid #f5f5f5;'
                          : ''
                      "
                      class="d-flex align-center justify-center"
                      style="font-weight: 700"
                    >
                      ${{ getSizePrice(sole, size, item) }}
                    </div>
                  </div>
                  <div class="item-container__title">
                    {{ $t('PRODUCT_DESCRIPTION.table.total') }}
                  </div>
                  <div class="position-relative item-container__content">
                    <div
                      :style="
                        index !==
                        item.sizes.filter((size) => size.soleId === sole?.id)
                          ?.length -
                          1
                          ? 'border-bottom: 1px solid #f5f5f5;'
                          : ''
                      "
                      class="d-flex align-center justify-center"
                      style="font-weight: 700"
                    >
                      $
                      {{ getFullPrice(item, sole) }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="item-container__castomization-btn d-flex justify-center align-center"
                style="grid-column: span 2"
              >
                <div style="width: 70%">
                  <BaseButton
                    title="Брендирование"
                    @click="openCustomizationDialog(item, sole)"
                  />
                </div>
              </div>
              <div class="item-container__castomization-logo pa-4">
                <div
                  class="d-flex justify-center align-center"
                  style="font-weight: 700"
                >
                  {{ $t('PRODUCT_DESCRIPTION.notes') }}
                </div>
                <div
                  v-if="item?.logo?.logo"
                  class="d-flex justify-center align-center"
                >
                  <img
                    style="max-width: 100%; max-height: 200px"
                    :src="getLogoPreview(item?.logo?.logo)"
                    :alt="getImageName(item?.logo?.logo)"
                    :title="getImageName(item?.logo?.logo)"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="cart__item mb-5">
            <div class="cart__item-container cart__item-container--top">
              <div>
                <div class="item-container__title">
                  {{ $t('PRODUCT_DESCRIPTION.table.image') }}
                </div>
                <div class="pa-4" style="max-height: 199px; height: 100%">
                  <img
                    style="width: 100%; height: 100%; object-fit: contain"
                    :src="item && baseUrl + item?.photo"
                    :alt="getImageName(item?.photo)"
                    :title="getImageName(item?.photo)"
                  />
                </div>
              </div>
              <div>
                <div class="item-container__title">
                  {{ $t('PRODUCT_DESCRIPTION.table.details') }}
                </div>
                <div class="pa-4">
                  <span style="font-weight: 700" class="mb-2 d-block">
                    {{ item?.name[activeLang.language] }}
                  </span>
                  <div>
                    <span style="font-weight: 700"
                      >{{ $t('PRODUCT_DESCRIPTION.branding') }}:
                    </span>
                    <ul class="pa-0 ma-0">
                      <li
                        v-for="(brand, index) in item.selected_brands"
                        :key="brand?.id"
                      >
                        <span>{{ index + 1 }}) </span>
                        <span>{{ brand?.name }} = {{ brand?.price }}$</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="item-container__inner">
                <div>
                  <div class="item-container__title">
                    {{ $t('PRODUCT_DESCRIPTION.table.price') }}
                  </div>
                  <div
                    v-for="(size, index) in item?.sizes"
                    :key="index"
                    :style="
                      index !== item?.sizes.length - 1
                        ? 'border-bottom: 1px solid #f5f5f5;'
                        : ''
                    "
                    class="position-relative item-container__content"
                  >
                    <div
                      class="d-flex align-center justify-center"
                      style="font-weight: 700"
                    >
                      {{ size?.price || 'not defined' }}$
                    </div>
                  </div>
                  <div class="item-container__title">
                    {{ $t('PRODUCT_DESCRIPTION.table.pre_count') }}
                  </div>
                  <div class="position-relative item-container__content"> </div>
                </div>
                <div>
                  <div class="item-container__title">
                    {{ $t('PRODUCT_DESCRIPTION.table.color') }}
                  </div>
                  <div
                    v-for="(size, index) in item?.sizes"
                    :key="index"
                    :style="
                      index !== item?.sizes.length - 1
                        ? 'border-bottom: 1px solid #f5f5f5;'
                        : ''
                    "
                    class="position-relative item-container__content"
                  >
                    <div class="d-flex align-center justify-center">
                      <img
                        class="item-container__content-color"
                        :src="
                          item?.colors
                            ?.filter((color) => size.colorId === color?.id)[0]
                            ?.photo_path.includes(baseUrl)
                            ? item?.colors?.filter(
                                (color) => size.colorId === color?.id
                              )[0]?.photo_path
                            : baseUrl +
                              item?.colors?.filter(
                                (color) => size.colorId === color?.id
                              )[0]?.photo_path
                        "
                        :alt="
                          getImageName(
                            item?.colors?.filter(
                              (color) => size.colorId === color?.id
                            )[0]?.photo_path
                          )
                        "
                        :title="
                          getImageName(
                            item?.colors?.filter(
                              (color) => size.colorId === color?.id
                            )[0]?.photo_path
                          )
                        "
                      />
                    </div>
                  </div>
                  <div class="item-container__title">
                    {{ $t('PRODUCT_DESCRIPTION.table.color') }}
                  </div>
                  <div class="position-relative item-container__content">
                    <div
                      class="d-flex align-center justify-center"
                      style="font-weight: 700; font-size: 20px"
                    >
                      {{ item?.colors?.length }}
                    </div>
                  </div>
                </div>
                <div>
                  <div class="item-container__title">{{
                    $t('PRODUCT_DESCRIPTION.table.size')
                  }}</div>
                  <div
                    v-for="(size, index) in item?.sizes"
                    :key="index"
                    :style="
                      index !== item?.sizes?.length - 1
                        ? 'border-bottom: 1px solid #f5f5f5;'
                        : ''
                    "
                    class="position-relative item-container__content"
                  >
                    <div
                      class="d-flex align-center justify-center"
                      style="font-weight: 700; font-size: 20px"
                    >
                      {{ size?.name }}
                    </div>
                  </div>
                  <div class="item-container__title">
                    {{ $t('PRODUCT_DESCRIPTION.table.size') }}
                  </div>
                  <div class="position-relative item-container__content">
                    <div
                      class="d-flex align-center justify-center"
                      style="font-weight: 700; font-size: 20px"
                    >
                      {{ item?.sizes?.length }}
                    </div>
                  </div>
                </div>
                <div>
                  <div class="item-container__title">
                    {{ $t('PRODUCT_DESCRIPTION.table.count') }}
                  </div>
                  <div
                    v-for="(size, index) in item?.sizes"
                    :key="index"
                    :style="
                      index !== item?.sizes?.length - 1
                        ? 'border-bottom: 1px solid #f5f5f5;'
                        : ''
                    "
                    class="position-relative item-container__content"
                  >
                    <div class="d-flex align-center justify-center pa-2">
                      <button
                        class="content__button content__button--decrease"
                        @click="size?.count > 1 ? size.count-- : ''"
                      >
                        -
                      </button>
                      <span
                        class="text-center px-3 py-1 mx-1"
                        style="border-radius: 5px; border: 1px solid #cecece"
                      >
                        {{ size?.count }}
                      </span>
                      <button
                        class="content__button content__button--increase"
                        @click="size.count++"
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div class="item-container__title">
                    {{ $t('PRODUCT_DESCRIPTION.table.count') }}
                  </div>
                  <div class="position-relative item-container__content">
                    <div
                      class="d-flex align-center justify-center"
                      style="font-weight: 700; font-size: 20px"
                    >
                      {{
                        item?.sizes?.reduce(
                          (sum, curr) => (sum += curr?.count),
                          0
                        )
                      }}
                    </div>
                  </div>
                </div>
                <div>
                  <div class="item-container__title">
                    {{ $t('PRODUCT_DESCRIPTION.table.total') }}
                  </div>
                  <div
                    v-for="(size, index) in item?.sizes"
                    :key="index"
                    class="position-relative item-container__content"
                  >
                    <div
                      :style="
                        index !== item.sizes?.length - 1
                          ? 'border-bottom: 1px solid #f5f5f5;'
                          : ''
                      "
                      class="d-flex align-center justify-center"
                      style="font-weight: 700"
                    >
                      $ {{ getSizePrice(null, size, item) }}
                    </div>
                  </div>
                  <div class="item-container__title">
                    {{ $t('PRODUCT_DESCRIPTION.table.total') }}
                  </div>
                  <div class="position-relative item-container__content">
                    <div
                      class="d-flex align-center justify-center"
                      style="font-weight: 700"
                    >
                      $
                      {{ getFullPrice(item) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="cart__item-container cart__item-container--bottom">
              <div
                class="item-container__castomization-btn d-flex justify-center align-center"
              >
                <div style="width: 70%">
                  <BaseButton
                    :title="$t('PRODUCT_DESCRIPTION.branding')"
                    @click="openCustomizationDialog(item)"
                  />
                </div>
              </div>
              <div class="item-container__castomization-logo pa-4">
                <div
                  class="d-flex justify-center align-center"
                  style="font-weight: 700"
                >
                  {{ $t('PRODUCT_DESCRIPTION.notes') }}
                </div>
                <div
                  v-if="item?.logo?.logo"
                  class="d-flex justify-center align-center"
                >
                  <img
                    style="max-width: 100%; max-height: 200px"
                    :src="getLogoPreview(item?.logo?.logo)"
                    :alt="getImageName(item?.logo?.logo)"
                    :title="getImageName(item?.logo?.logo)"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
    <template v-else-if="readonly">
      {{ $t('PRODUCT_DESCRIPTION.table.order_details') }}
    </template>

    <v-dialog v-model="castomizationDialog" :max-width="700">
      <v-card v-if="getItems[0]?.soles?.length">
        <Castomization
          :logo="editing_item && editing_item.logo"
          :brands="my_brands"
          :selected_brands="selected_brands"
          @onPositionChange="onPositionChange"
          @onLogoChange="editing_item.logo.logo = $event"
        />
      </v-card>
      <v-card v-else>
        <Castomization
          :logo="editing_item && editing_item.logo"
          :brands="my_brands"
          :selected_brands="selected_brands"
          @onPositionChange="onPositionChange"
          @onLogoChange="editing_item.logo.logo = $event"
        />
      </v-card>
    </v-dialog>

    <div v-if="!isEmptyCart" class="col-sm-12 px-0">
      <ul class="total_order pa-0 d-flex justify-space-between">
        <li class="sum_count">
          <p class="btn_total">
            <b class="btn_product_text mr-4"
              >{{ $t('PRODUCT_DESCRIPTION.second_table.totalCount') }}: </b
            ><span class="btn_product_sum">{{ totalItemsQuantity }}</span>
          </p>
        </li>
        <li class="btn_item">
          <p class="btn_total">
            <span class="btn_product_text mr-4"
              >{{ $t('PRODUCT_DESCRIPTION.second_table.sum') }}: </span
            ><span class="btn_product_sum">{{ getItemsFullPrice() }}</span>
          </p>
        </li>
        <li class="btn_item">
          <p class="btn_total">
            <span class="btn_product_text mr-4"
              >{{ $t('PRODUCT_DESCRIPTION.second_table.discount') }}: </span
            ><span class="btn_product_sum">0%</span>
          </p>
        </li>
        <li class="btn_item">
          <p class="btn_total">
            <span class="btn_product_text mr-4"
              >{{ $t('PRODUCT_DESCRIPTION.second_table.saving') }}: </span
            ><span class="btn_product_sum">{{ 0 }}</span>
          </p>
        </li>
        <li class="btn_item">
          <p class="t_order_total btn_total">
            <span class="btn_product_text mr-4"
              >{{ $t('PRODUCT_DESCRIPTION.second_table.total') }}: </span
            ><span class="btn_product_sum">{{ getItemsFullPrice() }}</span>
          </p>
        </li>
        <li class="btn_item">
          <p class="t_order_total btn_total">
            <span class="btn_product_text mr-4"
              >{{ $t('PRODUCT_DESCRIPTION.second_table.saved') }}:
            </span>
            <span class="btn_product_sum">
              {{
                notifications?.reduce((sum, curr) => (sum += curr.saving), 0) +
                0
              }}
            </span>
          </p>
        </li>
      </ul>
    </div>
  </main>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import BaseButton from '../layout/BaseButton.vue'
import Castomization from '../content/Castomization.vue'
import { getImageName } from '@/utils/utils'
const { mapState: State_notifications } =
  createNamespacedHelpers('notifications')
export default {
  name: 'AdminBasketList',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    reorder: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  filters: {
    fixFloat(val) {
      return val.toFixed(2)
    },
  },
  components: {
    BaseButton,
    Castomization,
  },
  data() {
    return {
      baseUrl: '',
      activeLang: '',
      savingPercentage: 5,
      isEmptyCart: false,
      castomizationDialog: false,
      logoPreview: '',

      customizingItem: {
        positions: [],
        logo: {},
      },

      branding_id: null,
      currentItem: {},
      currentSole: {},

      my_brands: [],
      selected_brands: [],
      editing_item: {},
      editing_sole: {},
    }
  },
  computed: {
    ...State_notifications(['notifications']),
    totalItemsQuantity() {
      let sum = 0
      this.getItems?.forEach((item) => {
        sum += item?.sizes.reduce((sum, curr) => (sum += curr.count), 0)
      })
      return sum
    },
    getItems() {
      return this.items
    },
    itemsSumPrice() {
      let sum = 0
      this.getItems?.forEach((item) => {
        item.colors.sizes.forEach((size) => {
          sum += item?.newPrice * size?.count
        })
      })
      return sum
    },
    totalPrice() {
      let sum = 0
      this.getItems?.forEach((item) => {
        sum += item.sizes.reduce(
          (sum, curr) => (sum += curr?.price * curr?.count),
          0
        )
        if (item?.price_type === 'reyestr' && item?.selected_brands) {
          if (item.selected_brands.length) {
            sum += item.selected_brands?.reduce(
              (sum, curr) => (sum += +curr?.brandPrice),
              0
            )
          }
        } else if (
          item?.price_type === 'sole' &&
          item?.soles[0]?.selected_brands
        ) {
          const brands = item?.soles?.map((el) => el.selected_brands).flat()
          if (item.soles?.length) {
            sum += brands?.reduce((sum, curr) => (sum += +curr?.brandPrice), 0)
          }
        } else {
          sum = sum
        }
      })

      return {
        fullPrice: sum,
        savingPrice: sum - (sum * 5) / 100,
        saving: (sum * 5) / 100,
      }
    },
    // totalPriceWithSaving() {
    //   return Math.floor(this.totalPrice.savingPrice)
    // },
    // saving() {
    //   return Math.floor(this.totalPrice.saving)
    // },
  },
  watch: {
    castomizationDialog(val) {
      if (!val) {
        this.customizingItem = {
          positions: [],
          logo: {},
        }
        this.branding_id = null
        this.currentItem = {}
        this.currentSole = {}
      }
    },
  },

  methods: {
    getImageName,
    getLogoPreview(value) {
      const logo = URL.createObjectURL(value)
      return logo
    },
    getFullPrice(item, sole) {
      let fullPrice
      const count = item.sizes.reduce((acc, curr) => {
        if (item.price_type === 'sole') {
          if (curr.soleId === sole.id) {
            return (acc += curr.count)
          } else {
            return 0
          }
        } else {
          return (acc += curr.count)
        }
      }, 0)
      if (item.price_type === 'reyestr') {
        fullPrice = item.selected_brands.reduce((acc, curr) => {
          if (curr.count) {
            const parts = curr?.price?.split('/')
            if (!curr?.boughtBrandsKit) {
              return (acc += parts[1] * count)
            } else {
              return (acc += parts[1] * count + curr.boughtBrandsKit * parts[0])
            }
          } else {
            return (acc += curr.brandPrice * count)
          }
        }, 0)
      } else {
        fullPrice = sole.selected_brands.reduce((acc, curr) => {
          if (curr.count) {
            if (!curr?.boughtBrandsKit) {
              return (acc += curr.brandPrice * count)
            } else {
              return (acc +=
                curr.brandPrice * count +
                curr.boughtBrandsKit * curr.brandsPrice)
            }
          } else {
            return (acc += curr.brandPrice * count)
          }
        }, 0)
      }
      const sizePrice = item.sizes.reduce((acc, curr) => {
        if (item.price_type === 'reyestr') {
          return (acc += curr.count * curr.price)
        } else {
          if (curr.soleId === sole.id) {
            return (acc += curr.count * curr.price)
          } else {
            return acc
          }
        }
      }, 0)
      return (fullPrice + sizePrice)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    getItemsFullPrice() {
      let fullPrice = 0
      let sizePrice = 0
      this.cartItems?.forEach((item) => {
        const brandsPrice =
          item.price_type === 'reyestr'
            ? item.selected_brands
            : item.soles.map((el) => el.selected_brands).flat()

        const count = item.sizes.reduce((acc, curr) => {
          return (acc += curr.count)
        }, 0)

        fullPrice += brandsPrice.reduce((acc, curr) => {
          if (curr.boughtBrandsKit) {
            return (acc +=
              curr.brandPrice * count + curr.boughtBrandsKit * curr.brandsPrice)
          } else {
            return (acc += curr.brandPrice * count)
          }
        }, 0)
        sizePrice += item.sizes.reduce((acc, curr) => {
          return (acc += curr.count * curr.price)
        }, 0)
      })
      this.$emit('getFullPrice', +fullPrice + +sizePrice)
      return (+fullPrice + +sizePrice)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },

    openCustomizationDialog(item, sole) {
      this.editing_item = item
      this.editing_sole = sole
      this.castomizationDialog = true
      if (item.price_type === 'sole') {
        this.my_brands = sole.brands
        this.selected_brands = sole.selected_brands.map((el) => el.id)
      } else {
        this.my_brands = item.reyestrItem.brands
        this.selected_brands = item.selected_brands.map((el) => el.id)
      }
    },
    onPositionChange(e) {
      this.selected_brands = e
      let my_brands = []
      let my_selected_brands = []
      if (this.editing_item.price_type === 'sole') {
        my_brands = this.editing_item.soles.find(
          (el) => el.id === this.editing_sole.id
        ).brands
        my_brands.forEach((brand) => {
          if (e.find((item) => item === brand.id)) {
            my_selected_brands.push(brand)
          }
        })
        this.editing_item.soles.find(
          (el) => el.id === this.editing_sole.id
        ).selected_brands = my_selected_brands
      } else {
        my_brands = this.editing_item.reyestrItem.brands
        my_brands.forEach((brand) => {
          if (e.find((item) => item === brand.id)) {
            my_selected_brands.push(brand)
          }
        })
        this.editing_item.selected_brands = my_selected_brands
      }

      // this.my_brands = []
      // this.selected_brands = []
      // this.editing_item = {}
      // this.editing_sole = {}

      // this.castomizationDialog = false
    },
    getSizePrice(sole, size, item) {
      let total
      let brandPrice
      const brands =
        item.price_type === 'sole' ? sole.selected_brands : item.selected_brands
      brandPrice = brands.reduce((acc, curr) => {
        if (curr.boughtBrandsKit) {
          return (acc +=
            curr.brandPrice * size?.count +
            curr.boughtBrandsKit * curr.brandsPrice)
        } else {
          return (acc += curr.brandPrice * size?.count)
        }
      }, 0)

      const sizePrice = size?.price * size?.count
      total = brandPrice + sizePrice
      return total
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
  },
  mounted() {
    this.baseUrl = this.$store.state.auth.API_URL
    this.activeLang = this.$store.state.language
    if (this.getItems.length) {
      this.isEmptyCart = true
    }
  },
}
</script>
<style scoped lang="scss">
$white: #ffffff;
$grey: #b5b5b5;
$gr: #f5f5f5;
$bl: #423f3d;
$black: #31343c;
$gr_c: #fbfbfb;
$grtx: #a4a4a4;
$green: #cfebcc;
$gr_col: #818181;
$green_c: #a3c2a0;
$bl_c: #90ace2;

@mixin ftz($fs, $fw, $col) {
  font-size: $fs;
  font-weight: $fw;
  color: $col;
}

.container_order {
  background-color: #fff;
}

.total_order {
  max-width: 1185px;
  overflow-x: auto;
}

.cart__item {
  display: grid;
}

.cart__item-container {
  display: grid;
  max-width: 1185px;
  overflow-x: auto;
  border: 1px solid $gr;

  &--top {
    grid-template-columns: 200px 200px 1fr;

    & > div:not(:last-child) {
      border-right: 1px solid $gr;
    }
  }

  &--bottom {
    grid-template-columns: 400px 1fr;
    border-top: none;
  }
}

.item-container {
  &__inner {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    & > div:not(:last-child) {
      border-right: 1px solid $gr;
    }
  }

  &__title {
    border-bottom: 1px solid $gr;
    background-color: $gr;
    text-transform: uppercase;
    @include ftz(16px, 400, $bl);
    text-align: center;
  }

  &__content {
    width: 100%;
    padding-top: 60px;

    & > div {
      position: absolute;
      inset: 0;
    }

    &-color {
      width: 50%;
      height: 50%;
    }
  }

  &__castomization-btn {
    height: 100%;
    border-right: 1px solid $gr;
  }

  &__castomization-logo {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
  }
}

.content {
  &__button {
    color: $grey;
    flex-grow: 1;
    cursor: pointer;

    &--increase {
      background-color: $green;
    }

    &--decrease {
      background-color: $gr;
    }
  }
}

.btn_item {
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  padding: 9px 26px;
  font-size: 16px;
  color: #666666;

  @media (max-width: 575px) {
    font-size: 13px;
    font-weight: 700 !important;
    width: 130px;
    padding: 5px 20px;
  }

  .btn_product_sum {
    font-size: 24px;
    font-family: 'Roboto Condensed';

    @media (max-width: 575px) {
      font-size: 13px;
    }
  }

  .btn_product_text {
    font-weight: bold !important;
    display: block;
    margin-bottom: 10px;
    margin-right: 0;
    text-transform: capitalize;
    width: 100%;
  }
}

.sum_count {
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  padding: 9px 26px;
  font-size: 16px;
  color: #666666;

  @media (max-width: 575px) {
    font-size: 13px;
    font-weight: bold !important;
    width: 130px;
    padding: 5px 20px;
  }

  .btn_product_sum {
    font-size: 24px;
    font-family: 'Roboto Condensed';

    @media (max-width: 575px) {
      font-size: 13px;
    }
  }

  .btn_product_text {
    font-weight: bold !important;
    display: block;
    margin-bottom: 10px;
    margin-right: 0;
    text-transform: capitalize;
    width: 100%;
  }
}
</style>
