import { render, staticRenderFns } from "./Castomization.vue?vue&type=template&id=4232ebd0&scoped=true&"
import script from "./Castomization.vue?vue&type=script&lang=js&"
export * from "./Castomization.vue?vue&type=script&lang=js&"
import style0 from "./Castomization.vue?vue&type=style&index=0&id=4232ebd0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4232ebd0",
  null
  
)

export default component.exports